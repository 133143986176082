import * as React from 'react';

import Container from '@mui/material/Container';

import 'typeface-titillium-web';

import '@dej611/spid-react-button/dist/index.css'

import { useParams } from 'react-router-dom'
import ImageGallery from "react-image-gallery";
import moment from 'moment';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Modal, Typography } from '@mui/material';
import { School } from '@mui/icons-material';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { AuthHelper } from '../helpers/AuthHelper';
import { loadStripe } from '@stripe/stripe-js';

function isDateGreaterThanToday(date) {
    // Create a moment object for the given date
    const givenDate = moment(date);

    // Create a moment object for today
    const today = moment().startOf('day'); // Start of the day to ignore time

    // Compare the dates
    return givenDate.isAfter(today);
}

export default function Accademy() {

  const [loading, setLoading] = React.useState(false)

  const [request, setRequest] = React.useState({
    type: "",
    service: "",
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  
  const [school, setSchool] = React.useState({});

  const [courses, setCourses] = React.useState([]);

  
  const { id } = useParams();

  const getSchool = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/${id}`);
    setSchool(await res.json());
  }

  const getCourses = async() => {
    const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/courses/digital`);
    setCourses([...await res.json()])
  }
  React.useEffect(() => {
    getSchool();
    getCourses();
  }, []);

  const [selectedCourse, setSelectedCourse] = React.useState("");
  const [selectedCourseObject, setSelectedCourseObject] = React.useState({});
  const stripePromise = loadStripe('pk_live_51NyF77LgIIqR4q8S4RXJM7IAUm7ZV2F8IUYYvWmWKJVsvjshbvFezHWwynONNqMcnSYFbCUrYwYcjyi6g9hkcvds008BLwq2N9')
  const [paymentIntent, setPaymentIntent] = React.useState(null);
  const [secretStripe, setSecretStripe] = React.useState(null);

  const createPaymentIntent = async(amount, course) => {
        try{
            const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/stripe/intent', {
                method: 'POST',
                body: JSON.stringify({amount: amount * 100})
            })
            const json = await res.json();

            setPaymentIntent(json.id);
            setSecretStripe(json.client_secret);
        }catch(ex){
            alert("Errore server");
        }
}

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const ref = React.useRef();
    const [_loading, _setLoading] = React.useState(false);

    return (<form onSubmit={async (e) => {
        e.preventDefault(); 
        if(_loading){
            alert('Stiamo elaborando la richiesta precedente');
            return;
        }
        _setLoading(true);
        try{

            const user = await AuthHelper.getUserFromIdToken();
            const res = await fetch('https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/prenotations', {
                method: 'POST',
                body: JSON.stringify({
                    profile: user.Username,
                    email: user?.UserAttributes?.filter(x => x.Name === 'email')[0].Value,
                    school: selectedCourse,
                    digital: true,
                    partecipant: user.Username,
                    payment: paymentIntent,
                    payment_status: 'COMPLETED',
                    accepted: true,
                    course_id: selectedCourse,
                    course: selectedCourse,
                    coursePayload: selectedCourseObject
                })
            })
            const {error} = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}`,
                },
            })
            if(error){
                if (error.type === "card_error" || error.type === "validation_error") {
                    alert(error.message)
                } else {
                    console.log(error);
                    alert('Errore server');
                    
                }
                return;
            }
          
           }catch(ex){
            console.log(ex);

            alert('ciao');
           }
        
        
        
        // setIsProcessing(false);
        }}> 
        <PaymentElement></PaymentElement>
        <button type="submit" disabled={_loading}>{_loading ? 'Aspetta...' : 'Paga ora'}</button>
    </form>);
}
  const StripeElement = () => {
    const options = {
        clientSecret: secretStripe
    }
    return <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
    </Elements> 
}

const [modalPayment, setModalPayment] = React.useState(false);
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'scroll',
    maxHeight: '90vh',
    p: 3,
  };

  const instantiateModal = async() => {
    await createPaymentIntent(500);
    setModalPayment(true);
  }
  React.useEffect(() => {
    if(selectedCourse !== ""){
        instantiateModal();
    }
  }, [
    selectedCourse
  ])
  return (
    <>
    {<div component="main" maxWidth="xs">
        {modalPayment && <Modal
                open={modalPayment}
                onClose={() => setModalPayment(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                  <StripeElement />
                </Box>
        </Modal>}
        
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',gap: 3}}>
        <School fontSize='large' /> <Typography fontSize={32} fontWeight={'bold'}>MASE Academy</Typography>
            
        </div>
        <p>Attraverso il servizio Academy, MASE offre un'ampia gamma di corsi digitali progettati per rispondere alle esigenze di formazione e certificazione dei marittimi.
        <br/>
        La piattaforma consente agli utenti di accedere a materiali formativi di alta qualità, ideali per prepararsi agli esami di passaggio di grado e ottenere abilitazioni specifiche per operare su particolari tipologie di navi, come petroliere, chimichiere o navi da crociera. I corsi sono strutturati in modo interattivo, combinando contenuti teorici con esercitazioni pratiche e simulazioni, per garantire un apprendimento efficace e mirato.
        <br/>
        Con MASE, i marittimi possono sviluppare nuove competenze, restare aggiornati sulle normative internazionali e accedere a opportunità di crescita professionale, tutto tramite un'esperienza digitale flessibile e facilmente accessibile.</p>

        <h1>I nostri corsi</h1>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
            {courses.filter(x => !x.link || !x.link.includes('oltremareservizi.it')).map(course => (
                     <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={course['cover']}
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            {course['title']}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            <strong>{course['type']} </strong><br/>
                            <i style={{fontSize: 8, textAlign: 'center'}}>{course['regulation']}</i><br />
                            {course['description']}
                            <br/><br/>
                            Prezzo: <strong>{Number(course['price']).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</strong>

                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => {
                                setSelectedCourseObject(course);
                                setSelectedCourse(course['id'])
                            }
                            }>Prenota</Button>
                        </CardActions>
                        </Card>
            ))}
        </div>
        <h1>I corsi oltremare</h1>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
            {courses.filter(x => x.link && x.link.includes('oltremareservizi.it')).map(course => (
                     <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={course['cover']}
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            {course['title']}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            <strong>{course['type']} </strong><br/>
                            <i style={{fontSize: 8, textAlign: 'center'}}>{course['regulation']}</i><br />
                            {course['description']}<br/><br/><br/>
                            Prezzo: <strong>{Number(course['price']).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</strong>

                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => {
                                setSelectedCourseObject(course);
                                setSelectedCourse(course['id'])
                            }
                            }>Prenota</Button>
                        </CardActions>
                        </Card>
            ))}
        </div>

      </div>}
    </>
      
  );
}