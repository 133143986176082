import * as React from 'react';

import Container from '@mui/material/Container';

import 'typeface-titillium-web';

import '@dej611/spid-react-button/dist/index.css'

import { useParams } from 'react-router-dom'
import ImageGallery from "react-image-gallery";
import moment from 'moment';
import { ArrowForwardIos, Close, ContactMail, DeleteForever, HistoryEdu, RequestQuote } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { AuthHelper } from '../helpers/AuthHelper';
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating, TextField, Typography } from '@mui/material';


moment.locale('it');
function isDateGreaterThanToday(date) {
    // Create a moment object for the given date
    const givenDate = moment(date);

    // Create a moment object for today
    const today = moment().startOf('day'); // Start of the day to ignore time

    // Compare the dates
    return givenDate.isAfter(today);
}

export default function Notifications() {

  const [loading, setLoading] = React.useState(false)

  const [request, setRequest] = React.useState({
    type: "",
    service: "",
    name: "",
    email: "",
    phone: "",
    message: ""
  })
  
  const [school, setSchool] = React.useState({});

  const [courses, setCourses] = React.useState([]);

  const [Notifications, setNotifications] = React.useState([]);

  const getTimePassed = (createdAt) => {
        const pastTimestamp = moment(createdAt);

        // Timestamp corrente
        const currentTimestamp = moment();

        // Differenza in millisecondi
        const differenceInMillis = currentTimestamp.diff(pastTimestamp);

        // Conversioni
        const seconds = moment.duration(differenceInMillis).asSeconds();
        const minutes = moment.duration(differenceInMillis).asMinutes();
        const hours = moment.duration(differenceInMillis).asHours();
        const days = moment.duration(differenceInMillis).asDays();
        const months = moment.duration(differenceInMillis).asMonths();

        if(seconds > 60){
          if(days > 30)return `${Math.floor(months)} mesi fa`;
          if(hours > 24)return `${Math.floor(days)} giorni fa`;
          if(minutes > 60)return `${Math.floor(hours)} ore fa`;
          return `${Math.floor(minutes)} minuti fa`;
        }else{
          return `${Math.floor(seconds)} secondi fa`;
        }
  }
//   const getNotifications = async() => {
//       const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;
//       const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/notifications/${_user.filter(x => x.Name === 'sub')[0]?.Value}`)
//       let notifications = await res.json();
//       console.log(notifications.map(x => getTimePassed(x.createdAt)));
//       setNotifications([...notifications]);
//   }

  const [prenotations, setPrenotations] = React.useState([]);

  const getUserPrenotations = async() => {
    const user = await AuthHelper.getUserFromIdToken();
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/prenotations/${user.Username}`);

    setPrenotations([...await res.json()]); 
    console.log('PRENOTAZIONI', prenotations);

}
  const sendRequest = async() => {
    setLoading(true);
    try{
      const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/services`, {
        method: 'POST',
        body: JSON.stringify(request)
      });
      setLoading(false);
      alert("Richiesta inviata con successo!");
    }catch(ex){
      alert('Errore server');
      console.log(ex);
      setLoading(false);
    }
    

  }

  const { id } = useParams();

  const getSchool = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/school/${id}`);
    setSchool(await res.json());
  }

  const getCourses = async() => {
    const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/courses/${id}`);
    setCourses([...await res.json()])
  }
  React.useEffect(() => {
    getSchool();
    getCourses();
    getUserPrenotations();
  }, []);

  const [tabActive, setTabActive] = React.useState(0);

  const tabs = [
    'Prossime',
    'Correnti',
    'Passate'
  ]



function ReviewsDialog(props) {

    const handleClose = () => {
      props.setOpen(false);
    };
    
    const [ratings, setRatings] = React.useState({
        utilita: 0,
        didattica: 0,
        materiale: 0,
        servizi: 0,
        text: ""
    });

    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Valuta la tua esperienza
          </DialogTitle>
          <DialogContent sx={{width: 400}}>
            <DialogContentText id="alert-dialog-description">
              <Typography>Utilità</Typography>
              <Rating
                name="simple-controlled"
                value={ratings.utilita}
                onChange={(event, newValue) => {
                    setRatings({...ratings, utilita: newValue})
                }}
                />
                <Typography>Didattica docenti</Typography>
                <Rating
                    name="simple-controlled"
                    value={ratings.didattica}
                    onChange={(event, newValue) => {
                        setRatings({...ratings, didattica: newValue})
                    }}
                    />
                <Typography>Materiale didattico</Typography>
                <Rating
                    name="simple-controlled"
                    value={ratings.materiale}
                    onChange={(event, newValue) => {
                        setRatings({...ratings, materiale: newValue})
                    }}
                />
                <Typography>Organizzazione e servizi</Typography>
                <Rating
                    name="simple-controlled"
                    value={ratings.servizi}
                    onChange={(event, newValue) => {
                        setRatings({...ratings, servizi: newValue})
                    }}
                />
                <Typography>Scrivi recensione</Typography>
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  fullWidth
                  maxRows={5}
                  minRows={4}
                  onChange={(e) => {
                    setRatings({...ratings, text: e.target.value})
                  }}
                />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)}>Annulla</Button>
            <Button onClick={async () => {

                const res = await fetch(`https://3vuz0d85jk.execute-api.eu-west-1.amazonaws.com/mase/api/v1/user/prenotations/review`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...props.payload,
                        ...ratings,
                        score: Object.values(ratings).filter(x => !isNaN(x)).reduce((a,b) => a + b, 0) / Object.values(ratings).filter(x => !isNaN(x)).length
                    })
                })

                alert('Recensione inviata con successo!');
                props.setOpen(false);
            }} autoFocus>
              Invia
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
function RequestDialog(props) {

    const handleClose = () => {
      props.setOpen(false);
    };
    
    const [payload, setPayload] = React.useState({
        request: "",
    });

    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Richiedi Informazioni
          </DialogTitle>
          <DialogContent sx={{width: 400}}>
            <DialogContentText id="alert-dialog-description">
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  fullWidth
                  maxRows={5}
                  minRows={4}
                  onChange={(e) => {
                    setPayload({...payload, request: e.target.value})
                  }}
                />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)}>Annulla</Button>
            <Button onClick={async () => {

                const res = await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/course/info`, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...props.payload,
                        ...payload,
                    })
                })

                alert('Richiesta inviata con successo!');
                props.setOpen(false);
            }} autoFocus>
              Invia
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  const [openDialog, setOpenDialog] = React.useState(false);
  const [payload, setPayload] = React.useState({});
  const [openInfo, setOpenInfo] = React.useState(false);
  return (
    <>
    {<div component="main" maxWidth="xs" style={{paddingLeft: 24, paddingRight: 24}}>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
            <div style={{display: 'flex', gap: 12}}>
                {tabs.map((tab, idx) => (
                    <Chip label={tab} onClick={() => setTabActive(idx)} style={{background: tabActive === idx ? 'rgba(6, 32, 105, .25)' : 'transparent', color: tabActive === idx ? 'rgb(6,32,105)' : '#333', fontWeight: 'bold', border: tabActive === idx ? '1px solid rgba(6, 32, 105, 1)' : 'none' }}></Chip>
                ))}
            </div>
            {
                tabActive === 0 && (
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12, marginTop: 24}}>
                        {
                            prenotations.filter(x => moment(x.from).diff(moment()) > 0).map(prenotation => (
                                <div style={{display: 'flex', flexDirection: 'column', background: 'rgba(0,0,0,.04)', gap: 12, paddingLeft: 14, paddingTop: 8, paddingBottom: 8, borderRadius: 5, width: '95%'}}>
                                    <div style={{display: 'flex', gap: 12}}>
                                        <img src={prenotation.logo} style={{width: 75, height: 75, objectFit: 'contain', borderRadius: 6}}></img>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <strong>{prenotation.nome_corso}</strong>
                                            <span>{moment(prenotation.from).format('DD')} - {moment(prenotation.to).format('DD')} {moment(prenotation.to).format('MMM')} • {Number(prenotation.price).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</span>
                                            <span>{prenotation.nome_scuola}</span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', width: '95%', paddingRight: 14, marginRight: 14, justifyContent: 'space-between', paddingTop: 6, paddingBottom: 6, alignItems: 'center'}} onClick={async() => {
                                        setOpenInfo(true);
                                        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;

                                        setPayload({
                                            school: prenotation.school,
                                            user: _user.filter(x => x.Name === 'sub')[0]?.Value,
                                            course: prenotation.course,
                                        })
                                        }}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 14}}>
                                            <ContactMail fontSize="large" />
                                            <span style={{fontSize: 18, fontWeight: 'bold'}}>Richiedi informazioni</span>
                                        </div>
                                        <ArrowForwardIos></ArrowForwardIos>
                                    </div>
                                    <div style={{display: 'flex', width: '95%', paddingRight: 14, marginRight: 14, justifyContent: 'space-between', paddingTop: 6, paddingBottom: 6, alignItems: 'center'}} onClick={async() => {
                                        await fetch(`https://0lp9rmohf7.execute-api.eu-west-1.amazonaws.com/mase2/api/v1/prenotation/${prenotation.partecipation_id}`, {
                                          method: 'DELETE'
                                      });
                                      await getUserPrenotations();
                                        }}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 14}}>
                                            <DeleteForever fontSize="large" />
                                            <span style={{fontSize: 18, fontWeight: 'bold'}}>Elimina</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            ))
                        }
                    </div>
                )
            }
            {
                tabActive === 2 && (
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12, marginTop: 24}}>
                        {
                            prenotations.filter(x => moment(x.from).diff(moment()) < 0).map(prenotation => (
                                <div style={{display: 'flex', flexDirection: 'column', background: 'rgba(0,0,0,.04)', gap: 12, paddingLeft: 14, paddingTop: 8, paddingBottom: 8, borderRadius: 5, width: '95%'}}>
                                    <div style={{display: 'flex', gap: 12}}>
                                        <img src={prenotation.logo} style={{width: 75, height: 75, objectFit: 'contain', borderRadius: 6}}></img>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <strong>{prenotation.nome_corso}</strong>
                                            <span>{moment(prenotation.from).format('DD')} - {moment(prenotation.to).format('DD')} {moment(prenotation.to).format('MMM')} • {Number(prenotation.price).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</span>
                                            <span>{prenotation.nome_scuola}</span>
                                        </div>
                                    </div>
                                    <hr style={{border: '1px solid #666', width: '80%', margin: '0 auto', marginTop: 12, marginBottom: 12}} />
                                    <div style={{display: 'flex', width: '95%', paddingRight: 14, marginRight: 14, justifyContent: 'space-between', paddingTop: 6, paddingBottom: 6, alignItems: 'center'}} onClick={async() => {
                                        setOpenDialog(true);
                                        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;

                                        setPayload({
                                            school: prenotation.school,
                                            user: _user.filter(x => x.Name === 'sub')[0]?.Value,
                                            course: prenotation.course
                                        })
                                        }}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 14}}>
                                            <HistoryEdu fontSize="large" />
                                            <span style={{fontSize: 18, fontWeight: 'bold'}}>Scrivi una recensione!</span>
                                        </div>
                                        <ArrowForwardIos></ArrowForwardIos>
                                    </div>
                                    <div style={{display: 'flex', width: '95%', paddingRight: 14, marginRight: 14, justifyContent: 'space-between', paddingTop: 6, paddingBottom: 6, alignItems: 'center'}} onClick={async() => {
                                        setOpenInfo(true);
                                        const _user = (await AuthHelper.getUserFromIdToken())?.UserAttributes;

                                        setPayload({
                                            school: prenotation.school,
                                            user: _user.filter(x => x.Name === 'sub')[0]?.Value,
                                            course: prenotation.course,
                                        })
                                        }}>
                                        <div style={{display: 'flex', alignItems: 'center', gap: 14}}>
                                            <ContactMail fontSize="large" />
                                            <span style={{fontSize: 18, fontWeight: 'bold'}}>Richiedi informazioni</span>
                                        </div>
                                        <ArrowForwardIos></ArrowForwardIos>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }

            <ReviewsDialog open={openDialog} setOpen={setOpenDialog} payload={payload}></ReviewsDialog>
            <RequestDialog open={openInfo} setOpen={setOpenInfo} payload={payload}></RequestDialog>
          </div>
    </div>}
    </>
      
  );
}